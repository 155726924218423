<script setup lang="ts">
import { formatReviewCount } from '../format';

const props = withDefaults(
  defineProps<{
    rating: number;
    reviewCount: number;
  }>(),
  {
    rating: 0.0,
    reviewCount: 0,
  }
);
</script>

<template>
  <div class="d-flex align-items-center gap-2">
    <AppRating :model-value="props.rating" />
    <div class="font-bold text-black-tertiary">
      {{ formatReviewCount(props.reviewCount) }}
    </div>
  </div>
</template>
